import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from 'react-router-dom';
import DynamicTable from './DynamicTable';
import ForecastChart from './ForecastChart';
import Navbar from './ui/Navbar'; // Importamos Navbar
import '../styles.css';

function Dashboard() {
  const { isAuthenticated, isLoading } = useAuth0();
  const [step, setStep] = useState(1);
  const [rows, setRows] = useState(1);
  const [cols, setCols] = useState(6);
  const [data, setData] = useState([]);
  const [unit, setUnit] = useState('months');
  const [productNames, setProductNames] = useState([]);
  const [alpha, setAlpha] = useState(0.5);

  const exponentialSmoothing = (data, alpha) => {
    return data.map((productData) => {
      let forecast = [productData[0]];
      for (let i = 1; i < productData.length; i++) {
        const nextForecast = alpha * productData[i] + (1 - alpha) * forecast[i - 1];
        forecast.push(nextForecast);
      }
      const lastObserved = productData[productData.length - 1];
      const lastForecast = forecast[forecast.length - 1];
      const nextPeriodForecast = alpha * lastObserved + (1 - alpha) * lastForecast;

      return {
        forecast,
        nextPeriodForecast,
      };
    });
  };

  const smoothedData = exponentialSmoothing(data, alpha);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="dashboard-container">
      <Navbar />
      {/* Resto del dashboard */}
      {step === 1 && (
        <form onSubmit={(e) => { e.preventDefault(); setStep(2); }} className="config-form">
          <div className="dashboard-card">
            <label>Número de Productos:</label>
            <input
              type="number"
              value={rows}
              onChange={(e) => setRows(Number(e.target.value))}
              min="1"
              className="input-field"
            />
          </div>
          <div className="dashboard-card">
            <label>Número de Fechas:</label>
            <input
              type="number"
              value={cols}
              onChange={(e) => setCols(Number(e.target.value))}
              min="1"
              className="input-field"
            />
          </div>
          <div className="dashboard-card">
            <label>Unidad de Tiempo:</label>
            <select value={unit} onChange={(e) => setUnit(e.target.value)} className="select-field">
              <option value="months">Meses</option>
              <option value="quarters">Trimestres</option>
              <option value="semesters">Semestres</option>
            </select>
          </div>
          <button type="submit" className="button">Siguiente</button>
        </form>
      )}
      {step === 2 && (
        <>
          <button onClick={() => setStep(1)} className="button">Volver a la Configuración</button>
          <DynamicTable
            rows={rows}
            cols={cols}
            unit={unit}
            productNames={productNames}
            onProductNameChange={(e, index) => {
              const newProductNames = [...productNames];
              newProductNames[index] = e.target.value;
              setProductNames(newProductNames);
            }}
            onSubmit={setData}
          />
          <div className="alpha-control">
            <h3>Ajustar α (Alpha)</h3>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={alpha}
              onChange={(e) => setAlpha(Number(e.target.value))}
            />
            <p>Valor actual de α: {alpha}</p>
          </div>
          {data.length > 0 && (
            <>
              <h3>Pronóstico Suavizado</h3>
              <ForecastChart data={smoothedData} productNames={productNames} originalData={data} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
