import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Button from './Button';
import styles from '../../Navbar.module.css';


const frontendLoginUrl = process.env.REACT_APP_REDIRECT_URI;

const Navbar = () => {
  const { user, logout } = useAuth0();

  const handleLogout = () => {
    console.log("Redirigiendo a:", frontendLoginUrl);
    logout({
      returnTo: frontendLoginUrl,
    });
    window.location.href = frontendLoginUrl; 
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navLeft}>
        <h1 className={styles.navTitle}>Bienvenido, {user?.email || 'Usuario'}</h1>
      </div>
      <div className={styles.navRight}>
        <Button onClick={handleLogout} className={styles.logoutButton}>
          Cerrar Sesión
        </Button>
      </div>
    </nav>
  );
};

export default Navbar;
