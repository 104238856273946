import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";

const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    // Componente de carga global mientras Auth0 verifica el estado
    return <div>Cargando...</div>;
  }

  return (
    <Routes>
      {/* Ruta de inicio de sesión */}
      <Route
        path="/login"
        element={
          isAuthenticated ? (
            <Navigate to="/dashboard" /> // Redirige al Dashboard si ya está autenticado
          ) : (
            <Login />
          )
        }
      />

      {/* Ruta protegida del Dashboard */}
      <Route
        path="/dashboard"
        element={
          isAuthenticated ? (
            <Dashboard />
          ) : (
            <Navigate to="/login" /> // Redirige a login si no está autenticado
          )
        }
      />

      {/* Ruta por defecto (Wildcard *) */}
      <Route
        path="*"
        element={
          isAuthenticated ? (
            <Navigate to="/dashboard" /> // Redirige al Dashboard si está autenticado
          ) : (
            <Navigate to="/login" /> // Redirige a login si no está autenticado
          )
        }
      />
    </Routes>
  );
};

export default App;
