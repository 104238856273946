import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import styles from "../login.module.css"; // Importa el CSS Module

const Login = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate(); // Permite manejar redirecciones con React Router

  useEffect(() => {
    // Si el usuario está autenticado, redirige al dashboard
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async () => {
    try {
      console.log("DEBUG: Attempting login");
      await loginWithRedirect(); // Redirige al flujo de autenticación Auth0
    } catch (err) {
      console.error("Error durante el login:", err);
    }
  };

  return (
    <div className={styles["login-container"]}>
      <h1 className={styles["welcome-title"]}>Bienvenido a SalesForecast</h1>
      <p className={styles["welcome-message"]}>
        Predice y visualiza tus ventas de manera eficiente con nuestra herramienta basada en datos.
      </p>
      {!isAuthenticated ? (
        <button className={styles["login-button"]} onClick={handleLogin}>
          Iniciar Sesión
        </button>
      ) : (
        <p className={styles["login-message"]}>
          Ya estás autenticado. Redirigiendo...
        </p>
      )}

      <div>
        <img className={styles["login-image"]} src="/images/Forecast.png" alt="Descripción de la imagen" />
      </div>
    </div>

    
  );
};

export default Login;
